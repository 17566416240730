<template>
  <div class="page">
    <Loader v-if="loader"></Loader>
    <div v-if="!loader" class="container fixed">
      <div class="page__title_main with-margin">Данные о хозяине и питомце</div>
      <div class="container__form with-margin">
        <RedactField
          v-for="(field, index) in fields"
          :field="field"
          :error="errors[field.name]"
          v-model="field.value"
          :key="'field_' + index"
        />
      </div>
      <a :href="`tel:${phoneLink}`">
        <Button text="Позвонить"></Button>
      </a>
      <Button
        style="margin-top: 16px"
        @click="sendGeoData"
        text="Поделиться Геолокацией"
      ></Button>
      <div
        v-if="stateRedact === 'start'"
        :class="disabled ? 'link_redact disabled' : 'link_redact'"
        @click="changeStateRedact('code')"
      >
        РЕДАКТИРОВАТЬ ДАННЫЕ
      </div>
      <div v-if="stateRedact === 'code'" class="link_code_desc">
        Код подтверждения будет отправлен на привязанный номер телефона
      </div>
      <div
        v-if="stateRedact === 'code'"
        :class="disabled ? 'link_code disabled' : 'link_code'"
        @click="redact()"
      >
        ЗАПРОСИТЬ КОД
      </div>
    </div>
  </div>
</template>

<!--stateRedact: start | code -->
<script>
import Button from "@/components/common/Button.vue";
import Loader from "@/components/4laps/Loader.vue";
import { axiosSimpleWrapper } from "../../helpers/dataHelpers";
import { notification } from "ant-design-vue";
import { logDebug } from "@/helpers/logHelper";
import RedactField from "@/components/4laps/RedactField.vue";
import json from "@/DUMP/fourLaps/allInfo.fields.json";

export default {
  name: "AllInfo",
  metaInfo: {
    meta: [
      {
        name: "format-detection",
        content: "telephone=yes",
      },
    ],
  },

  components: {
    RedactField,
    Button,
    Loader,
  },
  data() {
    return {
      errors: {},
      phone: "",
      phoneLink: "",
      fields: [],
      loader: true,
      disabled: false,
      uuid: "",
      stateRedact: "start",
    };
  },
  mounted() {
    this.fields = json;
    this.uuid = this.$route.params.point_url;

    if (this.$route.params.point_url) {
      logDebug(this.$route.params.point_url);
      const req = {
        method: "get",
        url: "/pets/" + this.$route.params.point_url,
      };
      axiosSimpleWrapper(
        req,
        (response) => {
          logDebug(response);
          if (response.data.success) {
            this.phone = response.data.data.phone;
            if (this.phone[0] !== "+" && this.phone[0] !== "8") {
              this.phoneLink = "8" + this.phone;
            } else {
              this.phoneLink = this.phone;
            }
            console.log('response.data', response.data)
            this.fields.map((field) => {
              if (response.data.data[field.name]) {
                // todo need to set in helpers check
                if (field.type === "phone") {
                  logDebug(response.data.data[field.name]);

                  if (response.data.data[field.name].slice(0, 2) === "+7") {
                    field.value = response.data.data[field.name].slice(
                      2,
                      response.data.data[field.name].length
                    );
                  } else if (
                    response.data.data[field.name].slice(0, 1) === "8"
                  ) {
                    field.value = response.data.data[field.name].slice(
                      1,
                      response.data.data[field.name].length
                    );
                  }  else if (response.data.data[field.name].slice(0, 1) === "9") {
                    field.value = '+7' + response.data.data[field.name];
                  } else {
                    field.value = response.data.data[field.name];
                  }
                } else {
                  field.value = response.data.data[field.name];
                }
              }
              return field;
            });
            // Check phone_2
            const phone_2 = this.fields.find((item) => item.name === "phone_2");
            if (phone_2 && !phone_2.value) {
              this.fields = this.fields.filter(
                (item) => item.name !== "phone_2"
              );
            }
            // Check phone_2
            const dop_name = this.fields.find(
              (item) => item.name === "dop_name"
            );
            if (dop_name && !dop_name.value) {
              this.fields = this.fields.filter(
                (item) => item.name !== "dop_name"
              );
            }

            if (phone_2 && !phone_2.value && dop_name && !dop_name.value) {
              this.fields = this.fields.filter(
                (item) => item.name !== "desc_dop_fields"
              );
            }
            // if ()
          } else {
            this.$router.push({
              path: "/registration",
              query: { uuid: this.$route.params.point_url },
            });
          }
          this.loader = false;
        },
        (error) => {
          console.log(error);
          this.$router.push({
            path: "/error",
          });
        }
      );
    } else {
      this.$router.push({
        path: "/error",
      });
      this.loader = false;
    }
  },
  methods: {
    changeStateRedact(value) {
      this.stateRedact = value;
    },
    redact() {
      if (this.disabled === false) {
        this.disabled = true;
        this.loader = true;
        const req = {
          method: "post",
          url: "/pets/sms/" + this.$route.params.point_url,
          data: {
            phone: this.phone,
          },
        };
        axiosSimpleWrapper(req, (response) => {
          if (response.data.success) {
            notification.open({
              message: response.data.message,
            });
            this.$router.push({
              path: "/redact-code",
              query: {
                phone: this.phone,
                uuid: this.$route.params.point_url,
              },
            });
          } else {
            notification.open({
              message: response.data.message,
            });
          }
        });
        this.loader = false;
        this.disabled = false;
      }
    },
    sendGeoData() {
      const successCallback = (position) => {
        logDebug(position);
        const req = {
          method: "post",
          url: "/location",
          data: {
            lat: position.coords.latitude.toString(),
            lon: position.coords.longitude.toString(),
            pet_uuid: this.uuid,
          },
        };
        axiosSimpleWrapper(req, (response) => {
          if (response.data.success) {
            notification.open({
              message: response.data.message,
            });
          } else {
            notification.open({
              message: "Сохранение геолокации - " + response.data.message,
            });
          }
        });
      };

      const errorCallback = (error) => {
        logDebug(error);
        notification.open({
          message: "Добавьте системные настройки чтобы поделиться геолокацией",
        });
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          successCallback,
          errorCallback
        );
      } else {
        notification.open({
          message: "Geolocation is not supported by this browser.",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
